function sendContact() {
  var valid = validateContact();

  var v = grecaptcha.getResponse();
  if(v.length === 0)
  {
      $("#mail-status").html("<p class='aw-mail-error'>You need to prove you're human.</p>");
  } else {
      if(valid) {
        $("#mail-status").html("<p class='aw-mail-success'>Message sending.</p>");
        jQuery.ajax({
          url: $("#submitURL").val() + "/_theme-templates/assets/aw-form-submit.php",
          data:'userName='+$("#userName").val()+'&userEmail='+$("#userEmail").val()+'&content='+$("#content").val()+'&destAddress='+$("#destAddress").val(),
          type: "POST",
          success:function(data){
            $("#mail-status").html(data);
            $("#userName").val("");
            $("#userEmail").val("");
            $("#content").val("");
            grecaptcha.reset();
          },
          error:function (data){
            $("#mail-status").html(data);
          }
        });
      } else {
        $("#mail-status").html("<p class='aw-mail-error'>Check the form for errors.</p>");
      }
  }
}

function validateContact() {
  var valid = true;
  $(".demoInputBox").css('background-color','');
  $(".demoInputBox").css('border','1px solid #cacaca');
  $(".info").html('');

  if(!$("#userName").val()) {
    $("#userName").css('border','1px solid #ff0000');
    valid = false;
  }
  if(!$("#userEmail").val()) {
    $("#userEmail").css('border','1px solid #ff0000');
    valid = false;
  }
  if(!$("#userEmail").val().match(/^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/)) {
    $("#userEmail").css('border','1px solid #ff0000');
    valid = false;
  }
  if(!$("#content").val()) {
    $("#content").css('border','1px solid #ff0000');
    valid = false;
  }

  return valid;
}

function sendContactCBS() {
  var valid = validateContactCBS();
  event.preventDefault();
  var v = grecaptcha.getResponse();
  if(v.length === 0)
  {
      $("#mail-status").html("<p class='aw-mail-error'>You need to prove you're human.</p>");
  } else {
      if(valid) {
        $("#mail-status").html("<p class='aw-mail-success'>Message sending.</p>");
        jQuery.ajax({
          url: $("#submitURL").val() + "/_theme-templates/assets/aw-form-submit-CBS.php",
          data:'userName='+$("#userName").val()+'&userEmail='+$("#userEmail").val()+'&userPhone='+$("#userPhone").val()+'&destAddress='+$("#destAddress").val(),
          type: "POST",
          success:function(data){
            $("#mail-status").html(data);
            $("#userName").val("");
            $("#userEmail").val("");
            $("#userPhone").val("");
            grecaptcha.reset();
          },
          error:function (data){
            $("#mail-status").html(data);
          }
        });
      } else {
        $("#mail-status").html("<p class='aw-mail-error'>Check the form for errors.</p>");
      }
  }
}

function validateContactCBS() {
  var valid = true;
  $(".demoInputBox").css('background-color','');
  $(".demoInputBox").css('border','1px solid #cacaca');
  $(".info").html('');

  if(!$("#userName").val()) {
    $("#userName").css('border','1px solid #ff0000');
    valid = false;
  }
  if(!$("#userEmail").val()) {
    $("#userEmail").css('border','1px solid #ff0000');
    valid = false;
  }
  if(!$("#userEmail").val().match(/^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/)) {
    $("#userEmail").css('border','1px solid #ff0000');
    valid = false;
  }
  if(!$("#userPhone").val()) {
    $("#userPhone").css('border','1px solid #ff0000');
    valid = false;
  }

  return valid;
}
